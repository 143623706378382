/* Default styles for larger screens */
.nav {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: sticky;
    top: 0;
    background-color: white;
}

img.logo {
    width: 100px;
    margin-left: 20px;
}

div.links {
    display: flex;
    flex-direction: row;
}

div.links ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
}

div.links a {
    text-decoration: none;
    color: black;
    font-weight: 200;
    margin: 30px;
    list-style: none;
}

/* In your Navbar.css or global stylesheet */
.navbar-link {
    text-decoration: none;
    color: black;
    font-weight: 200;
    margin: 30px;
    list-style: none;
}

div.links ul a.navbar-link-active {
    font-weight: bold;
}

div.links.open {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: black;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    z-index: 1;
}

button.menu-btn {
    display: none;
}

span.menu-icon {
    font-size: 30px;
    margin-right: 20px;
}

button.close-btn {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 30px;
}


/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .nav {
        justify-content: space-around;
    }

    div.links {
        display: none;
        width: 100%;
        flex-direction: column;
        text-align: center;
    }

    div.links ul {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    div.links ul a {
        color: white;
    }

    div.links ul a.navbar-link-active{
        font-weight: bold;
    }

    button.close-btn{
        color: white;
    }

    button.menu-btn {
        display: block;
        margin-left: auto;
        background: none;
        border: none;
        cursor: pointer;
    }

    img.logo {
        padding: 10px;

    }

    button.close-btn {
        display: block;
    }
}