:root {
    --size: 50vmin;
    --white: #fff;
    --blue: #3051f1;
    --purple: #c92bb7;
    --red: #f73344;
    --orange: #fa8e37;
    --yellow: #fcdf8f;
    --yellow_to: #fbd377;
    --darkblue:rgb(0, 185, 241);
    --lightblue:#44c0e6;
}

div.footer {
    display: flex;
    justify-content: space-around;
    background-color: black;
    color: white;
    padding: 60px;
}

div.comInfo {
    float: right;
}

div.comInfo > h1,div.comInfo > p {
    color: #e8e8e8;
}

li {
    list-style: none;
    margin-top: 30px;
}

li a {
    color: white;
    font-weight: 400;
    text-decoration: none;
}

div.newsletter input {
    width: 200px;
    padding: 10px;
    border-radius: 7px;
    margin-right: 10px
}

div.newsletter button {
    width: 80px;
    padding: 10px;
    background-color: var(--darkblue);
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    border-radius: 7px;
}


div.socialimg {
    text-align: center;
}

div.socialimg img {
    width: 150px;
}

div.socialimg div.social {
    margin-top: 20px;
}

div.socialimg div.social a {
    margin: 0px;
}

div.socialimg div.social a i {
    font-size: 30px;
    color: white;
    margin: 10px;
    padding: 10px;
    padding-inline: 12px;
}

div.socialimg div.social a #whatsapp:hover {
    background-color: green;
    padding: 10px;
    padding-inline: 12px;
    border-radius: 100px;
}

div.socialimg div.social a #instagram:hover {
    color: white;
    background: radial-gradient(circle farthest-corner at 28% 100%,
            var(--yellow) 0%,
            var(--yellow_to) 10%,
            var(--orange) 22%,
            var(--red) 35%,
            transparent 65%), linear-gradient(145deg, var(--blue) 40%, var(--purple) 70%);
    padding: 10px;
    padding-inline: 12px;
    border-radius: 100px;
}

div.socialimg div.social a #email:hover {
    color: white;
    background: linear-gradient(145deg,var(--darkblue)  40%, var(--lightblue) 10%);
    padding: 10px;
    padding-inline: 12px;
    border-radius: 100px;
}


/* Responsive styles for smaller screens */

@media (max-width: 1024px) {

    div.footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        padding: 10px;
    }

    div.comInfo {
        float: none;
        margin-top: 20px;
    }

    div.comInfo p{
        font-size: 14px;
        margin: 10px;
    }

    div.comInfo h2, div.comInfo h1{
        font-size: 14px;
        margin: 10px;
    }

    div.linked {
        width: 80%;
        margin: auto;
    }

    div.linked ul{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-left: 0px;
    }

    div.linked ul li{
        margin-top: 10px;
    }

    div.linked ul li a{
        font-size: 12px;
    }

    div.newsletter{
        width: 80%;
    }

    div.newsletter h2{
        font-size: 14px;
    }

    div.newsletter p{
        font-size: 12px;
    }    

    div.newsletter input{
        width: 80%;
        padding: 5px;
        font-size: 12px;
    }

    div.newsletter button{
        margin-top: 10px;
        padding: 5px;
        font-size: 12px;
    }

    div.socialimg{
        width: 40%;
        justify-content: center;
    }

    div.socialimg img{
        width: 50%;
    }

    div.socialimg div.social{
        display: flex;
        justify-content: center;
    }

    div.socialimg div.social a{
        width: 25%;
    }

    div.socialimg div.social a i{
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    div.footer {
        flex-direction: row;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        padding: 10px;
    }

    div.comInfo {
        float: none;
        margin-top: 20px;
    }

    div.comInfo p{
        font-size: 12px;
    }

    div.comInfo h2, div.comInfo h1{
        font-size: 14px;
    }

    div.linked {
        width: 40%;
    }

    div.linked ul{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
    }

    div.linked ul li{
        margin-top: 10px;
    }

    div.linked ul li a{
        font-size: 12px;
    }

    div.newsletter{
        width: 40%;
    }

    div.newsletter h2{
        font-size: 14px;
    }

    div.newsletter p{
        font-size: 12px;
    }    

    div.newsletter input{
        width: 80%;
        padding: 5px;
        font-size: 12px;
    }

    div.newsletter button{
        margin-top: 10px;
        padding: 5px;
        font-size: 12px;
    }

    div.socialimg{
        width: 50%;
        justify-content: center;
    }

    div.socialimg img{
        width: 50%;
    }

    div.socialimg div.social{
        display: flex;
        justify-content: center;
    }

    div.socialimg div.social a{
        width: 25%;
    }

    div.socialimg div.social a i{
        font-size: 18px;
    }
}

@media (max-width: 375px) {
    div.footer {
        flex-direction: row;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        padding: 10px;
    }

    div.comInfo {
        float: none;
        margin-top: 20px;
    }

    div.comInfo p{
        font-size: 12px;
    }

    div.comInfo h2, div.comInfo h1{
        font-size: 14px;
    }

    div.linked {
        width: 35%;
    }

    div.linked ul{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: auto;
    }

    div.linked ul li{
        margin-top: 10px;
    }

    div.linked ul li a{
        font-size: 12px;
    }

    div.newsletter{
        width: 40%;
    }

    div.newsletter h2{
        font-size: 14px;
    }

    div.newsletter p{
        font-size: 12px;
    }    

    div.newsletter input{
        width: 80%;
        padding: 5px;
        font-size: 12px;
    }

    div.newsletter button{
        margin-top: 10px;
        padding: 5px;
        font-size: 12px;
    }

    div.socialimg{
        width: 40%;
        justify-content: center;
    }

    div.socialimg img{
        width: 50%;
    }

    div.socialimg div.social{
        display: flex;
        justify-content: center;
    }

    div.socialimg div.social a{
        width: 25%;
    }

    div.socialimg div.social a i{
        font-size: 18px;
    }
}