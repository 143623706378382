div.contact {
    width: 70%;
    display: flex;
    justify-content: space-between;
    background-color: #E8E8E8;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    border: 2px solid #E8E8E8;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

form {
    width: 35%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

form>input,form > textarea {
    width: 100%;
    padding: 3%;
    margin: 5px;
    border: none;
    border-radius: 5px;
}

form>button {
    width: 100px;
    padding: 3%;
    margin: 5px;
    border-radius: 5px;
    background-color: black;
    color: white;
}

div.contact>img {
    width: 50%;
    background-color: white;
    margin: 2px;
}

@media (max-width: 768px) {

    form{
        width: 100%;
        align-items: center;
        padding: 10px;
    }

    form>input,form > textarea,form>button{
        width: 80%;
    }

    div.contact>img{
        display: none;
    }
}