.first {
    padding: 10%;
    margin: 1% 0 10%;
    text-align: center;
  }
  
  .first h2 {
    font-size: 36px;
  }
  
  .maindiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: auto;
  }
  
  .maindiv .main1 {
    width: calc(25% - 20px);
    background-color: white;
    margin: 5px;
    padding: 10px;
    align-items: center;
    border-radius: 10px;
    margin-top: 5%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    justify-content: center;
  }
  
  .main1 > img {
    width: 100%;
    max-width: 200px; /* Ensure image doesn't overflow container */
  }
  
  /* Media queries for responsiveness: */
  
  @media (max-width: 768px) {
    .first h2{
      font-size: 26px;
    }

    .maindiv .main1 {
      width: calc(40% - 20px); /* 2 columns on smaller screens */
    }

    .maindiv .main1 h3{
      font-size: 16px;
    }

  }
  
  @media (max-width: 375px) {
    .first h2{
      font-size: 26px;
    }
    .maindiv .main1 {
      width: calc(40% - 20px);
    }

    .maindiv .main1 h3{
      font-size: 14px;
    }
  }
  