
div.maincard{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

div.card{
    width: 17%;
    padding: 20px;
    margin: 10px;
    margin-bottom: 60px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

div.card > img{
    width: 100%;
}

div.card > h3{
    font-size: 18px;
}

div.card > div.text{
    display: flex;
    background-color: #e8e8e88e;
    padding: 10px;
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
}

div.card > div.text > p{
    font-size: 14px;
    font-weight:600;
}

div.card > div.text > a{
    width: 50%;
    height: 50%;
    font-size: 21px;
    color: white;
    background-color: green;
    padding: 3%;
    text-align: center;
    border-radius: 30px;
    margin: 10px;
}

@media (max-width: 768px) {

    div.card{
        width: 70%;
    }

    div.card > h3{
        font-size: 16px;
    }

    div.card > p {
        font-size: 14px;
        text-align: justify;
    }

    div.card > div.text > p{
        font-size: 14px;
    }

    div.card > div.text > a{
        width: 25px;
        height: 25%;
        font-size: 20px;
        padding: 5px;
    }
}

@media (max-width: 375px) {

    div.card{
        width: 70%;
    }

    div.card > h3{
        font-size: 14px;
    }

    div.card > p {
        font-size: 12px;
        text-align: justify;
    }

    div.card > div.text > p{
        font-size: 12px;
    }

    div.card > div.text > a{
        width: 25px;
        height: 25%;
        font-size: 20px;
        padding: 5px;
    }

}