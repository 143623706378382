.main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

div.left {
    width: 45%;
}

.discription {
    color: #4D4C47;
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 170%;
}

.heading {
    color: #000;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -1.44px;
}

div.right {
    width: 45%;
}

div.right img {
    width: 100%;
}

.pdiv {
    margin-top: 15%;

}

.pdiv h2 {
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

div.pdiv {
    background-color: #E8E8E8;
    color: black;
    padding: 4%;
}

div.pdiv div.pic {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


div.pic img {
    width: 90%;
    height: 100%;
    margin: 10px;
    border-radius: 20px;
}


@media (max-width: 768px) {

    /* Styles for tablets */
    div.left {
        width: 80%;
    }

    .right {
        display: none;
    }
    
}

@media (max-width: 375px) {

    .pdiv h2 {
        font-size: 28px;
    }

    div.left h1.heading{
        font-size: 36px;
    }

    div.left h3.discription{
        font-size: 18px;
    }

    .pic img {
        width: 70%;
    }
}