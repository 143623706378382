.mission {
    display: flex;
    flex-direction: column; /* Ensure content stacks on smaller screens */
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 2.5% auto;
    background-color: #E8E8E8;
    border-radius: 10px;
    padding: 30px;
  }
  
  .mission img {
    width: 100%; /* Ensure image covers full width */
    max-width: 30%; /* Limit maximum width for larger screens */
    padding: 4%;
    border-radius: 20px;
    background-color: white;
  }
  
  .main1 {
    text-align: center;
    color: black;
  }
  
  .main1 h2 {
    width: 50%;
    background-color: black;
    color: white;
    padding: 10px;
    margin: 5% auto 0;
    margin-bottom: 20px;
  }
  
  .mission h4 {
    font-size: 18px;
    font-weight: 300;
    margin-left: 0; /* Remove unnecessary margin */
    text-align: center;
  }
  
  .main3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .main2 {
    width: 40%;
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .main2 img {
    width: 100%;
    max-width: 80%;
  }
  
  .main2 h4 {
    margin-left: 0;
    padding: 20px;
  }
  
  
  @media (max-width: 768px) {
    .mission {
      width: 100%;
      padding: 0;
      border-radius: 0;
      margin: 0;
    }
  
    .main3 .main2 {
      width: calc(40% - 20px);
      border-radius: 5px;
    }
  }
  
  @media (max-width: 480px) {

    .mission .main1 h2{
      font-size: 18px;
      
    }

    .main3 .main2 {
      width: calc(80% - 20px);
      border-radius: 5px;
    }

    .main3 .main2 h3{
      font-size: 16px;
    }

    .main3 .main2 img{
      width: 80%;
    }

    .main3 .main2 h4{
      font-size: 14px;
    }


  }
  